* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  height: 100vh;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .content {
    flex-grow: 1;
    padding: 0px 20px;
  }
}
