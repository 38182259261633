.menuInforme {
  z-index: 999;
}
.menuInforme li {
  background: var(--global-bg);
  border: none;
  width: fit-content;
  display: block;
  position: relative;
  width: '100%';
}

.menuInforme li button {
  width: 100%;
  justify-content: space-between;
}

.menuInforme button {
  padding: 5px;
}

.menuInforme ul {
  width: 100%;
  display: none;
  z-index: 999;
  background: var(--global-bg);
  position: absolute;
  top: 100%;
  left: 25%;
}

.menuInforme li ul {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  /* border: 1px solid red; */
}

.menuInforme li:hover > ul {
  display: block;
}
