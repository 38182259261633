.card-subheader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.gestion-documentos-buscador {
  margin-bottom: 2rem;
}

.gestion-documentos-buscador-label {
  display: flex;
  justify-content: center;
}

.document-status-label-sin {
  margin: 10px 0px 0px 10px;
  background-color: #ff9b9b;
  width: fit-content;
  border-radius: 7px;
  padding: 0px 10px 0px 10px;
  font-weight: bold;
}

.document-status-label-con {
  margin: 10px 0px 0px 10px;
  background-color: #cbffa9;
  width: fit-content;
  border-radius: 7px;
  padding: 0px 10px 0px 10px;
  font-weight: bold;
}
